import Grid from "@mui/material/Grid";
import { CFSHomeItem } from "src/pages/index.page";
import homeBannerstyl from "./homeBanner.module.css";
import landingBanner from "../../../public/images/newlanding.png";
import Image from "next/image";
import { useGetProjectsQuery } from "@graphql-generated";
import React, { useMemo } from "react";
import BreadcrumbsComponent from "@components/breadcrumbsComponent/Breadcrumbs";
import { Configuration } from "@constants";
import parse from 'html-react-parser';
import { getValidUrl, isValidImageUrl, isVideo } from "src/utils/commonCheck";

interface HomeBannerProps {
  cfsItem?: CFSHomeItem;
  exploreAction?: () => void;
  projectDetails?: any;
}

export default function HomeBannerComponent({
  cfsItem,
  exploreAction,
  projectDetails,
}: HomeBannerProps) {
  const {
    data: projectData,
  } = useGetProjectsQuery({
    variables: { marketplaceId: Configuration.MARKETPLACE_ID },
    fetchPolicy: 'no-cache'
  });

  const filterContenfullData = cfsItem?.items?.filter((item: any) => item?.sys?.id === Configuration?.CONTENTFUL_LANDINGPAGE_ENTRYID);

  let projDetail =
    cfsItem &&
    filterContenfullData &&
    filterContenfullData?.[0] &&
    filterContenfullData?.[0]?.fields?.projectList?.filter(
      (item: any) => item?.fields?.mojitoCollectionId === projectDetails
    );
  let noOfNfts = projectData?.marketplace?.collections?.filter(
    (item: any) => item?.id === projectDetails
  );

  const getImagedata = useMemo(() => {
    if (cfsItem && filterContenfullData && projDetail?.length === 0 && filterContenfullData?.[0]?.fields?.bannerVideoUrl) {
      return filterContenfullData[0]?.fields?.bannerVideoUrl;
    } if (isVideo(projDetail?.[0]?.fields?.projectImageUrl)) {
      return projDetail[0]?.fields?.projectImageUrl;
    } if (projDetail?.[0]?.fields?.projectImage?.fields?.file?.url) {
      return projDetail[0]?.fields?.projectImage?.fields?.file?.url;
    }
    return '';
  }, [filterContenfullData, projDetail, cfsItem]);

  return (
    <>
      {projectDetails && cfsItem && filterContenfullData && filterContenfullData?.[0] && (
        <div style={{ paddingTop: "0px!important" }} className="container">
          <BreadcrumbsComponent
            name={
              projDetail && projDetail[0] && projDetail[0]?.fields?.projectName
            }
            noLink={true}
          />
        </div>
      )}
      <Grid
        container
        spacing={2}
        className={`${"container"} ${homeBannerstyl.alignItemCenter
          } padding-section`}
      >
        <Grid item xs={12} md={6} sm={6}>
          <div>
            {!projectDetails && cfsItem && filterContenfullData && filterContenfullData?.[0] ? (
              <>
                <h1 className={homeBannerstyl.mainHeading}>
                  {filterContenfullData[0].fields.title}
                  <span> {filterContenfullData[0].fields.subTitle}</span>
                </h1>

                <p className={homeBannerstyl.para}>
                  {parse((filterContenfullData[0].fields.description ?? "").replaceAll("\n", "<br/>"))}
                </p>
              </>
            ) : (
              <>
                <h1 className={ projDetail?.[0]?.fields?.projectName?.length > 30 ? homeBannerstyl.mainHeadingSmall : homeBannerstyl.mainHeading}>
                  {projDetail &&
                    projDetail[0] &&
                    projDetail[0]?.fields?.projectName}{" "}
                  <br />
                  {/* {projDetail &&
                    projDetail[0] &&
                    projDetail[0]?.fields?.artistName} */}
                </h1>
                {/* <h3 className={homeBannerstyl.totalnft}>
                Total NFTs :{" "}
                {noOfNfts && noOfNfts[0] && noOfNfts[0]?.itemsCount}
              </h3> */}
                <p className={homeBannerstyl.para}>
                  {projDetail &&
                    projDetail[0] &&
                    parse((projDetail[0]?.fields?.descriptionOfProject ?? "").replaceAll("\n", "<br/>"))}
                </p>
              </>
            )}

            {/* <ButtonComponent
                        text="Explore Now"
                        variant="outlined"
                        cls="primary"
                        handleClick={exploreAction}
                    /> */}
          </div>
        </Grid>
        <Grid item xs={12} md={6} sm={6} className={homeBannerstyl.textEnd}>
          {!cfsItem && (
            <div className={homeBannerstyl.imgblock}>
              <Image
                alt="home-banner"
                objectFit="contain"
                src={landingBanner}
                layout="responsive"
                width={100}
                height={100}
                unoptimized
              />
            </div>
          )}
          { isValidImageUrl(getImagedata) ? (
            <div className={homeBannerstyl.imgblock}>
              <Image
                alt="home-banner"
                objectFit="contain"
                src={getImagedata}
                layout="responsive"
                width={100}
                height={100}
                unoptimized
              />
            </div>
          ) : (
            <div className={homeBannerstyl.videoBlock}>
              {getImagedata ? (
                <video
                  controls
                  className="img-responsive"
                  loop
                  muted
                  playsInline
                  autoPlay={true}
                >
                  <source src={getImagedata} />{" "}
                </video>
              ) : (
                <>
                  {projDetail && projDetail?.[0] && (
                    <video
                      controls
                      className="img-responsive"
                      loop
                      muted
                      playsInline
                      autoPlay={true}
                    >
                      <source
                        src={getValidUrl(getImagedata)}
                      />{" "}
                    </video>
                  )}
                </>
              )}
            </div>
          ) }
          {/* <img
                    src="/images/landing_banner.png"
                    className="img-responsive"
                /> */}
        </Grid>
      </Grid>
    </>
  );
}
