import HomeBannerComponent from "@components/homeBanner/homeBanner";
import React, { useEffect, useState } from "react";
import styles from "./newlanding.module.css";
import Grid from "@mui/material/Grid";
import NftCollectionCard from "@components/NFTCollectionCard/NftCollectionCard";
import AboutCont from "@components/AboutCont/AboutCont";
import ButtonComponent from "@components/buttonComponent/Button";
import { faqConst } from "src/constants/faq";
import FaqList from "@components/FaqListComp/faqComponent";
import { useRouter } from "next/router";
import { useAuth0 } from "@auth0/auth0-react";
import { useGetCurrentOrganizationUserLazyQuery } from "@graphql-generated";
import LoaderComponent from "@components/Paceloader/Loader";

const NewLanding = ({ homeCfs, projectMojito }: any) => {
  const router = useRouter();
  const { isAuthenticated } = useAuth0();
  const [routeLoading, setRouteLoading] = useState(false);
  const scrollToList = () => {
    // helloRef.current.scrollIntoView({ behavior: 'smooth' });
    document
      .getElementById("cardContainer")
      ?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    if (isAuthenticated) {
      getCurrentOrgUser();
    }
  }, [isAuthenticated]);
  const [
    getCurrentOrgUser,
    {
      data: currentOrgdata,
      loading: currentOrgloading,
      error: currentOrgerror,
    },
  ] = useGetCurrentOrganizationUserLazyQuery({
    variables: {
      curOrg: process.env.NEXT_PUBLIC_API_ORGANIZATION_ID,
    },
  });

  if (
    currentOrgdata?.me?.userOrgs[0]?.kycStatus === "None" &&
    localStorage.getItem("path") === "fromLogin"
  ) {
    localStorage.removeItem("path");
    setRouteLoading(true);
    router.push("/kyc");
  }
  
  return (
    <>
      {routeLoading ? (
        <LoaderComponent />
      ) : (
        <div>
          {homeCfs && (
            <HomeBannerComponent
              cfsItem={homeCfs}
              exploreAction={scrollToList}
            />
          )}
          <div className={`container padding-section ${styles.proj_wrp}`}>
            <div className={`${styles.heading}`}>
              <h2>Pace Verso Projects</h2>
            </div>
            <Grid container spacing={{ xs: 4, md: 7 }}>
              {homeCfs &&
                homeCfs?.items &&
                homeCfs?.items[0]?.fields?.projectList
                  ?.filter((item: any, index: number) => index < 2)
                  ?.map((proj: any, index: number) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <NftCollectionCard
                        projContent={proj?.fields}
                        projMojito={
                          projectMojito?.marketplace?.collections?.filter(
                            (item: any) =>
                              item?.id === proj?.fields?.mojitoCollectionId
                          )?.[0]
                        }
                      />
                    </Grid>
                  ))}

              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
                className={`${styles.btnexp}`}
              >
                <div>
                  <ButtonComponent
                    text="Explore More"
                    variant="outlined"
                    cls="secondary"
                    handleClick={() => router.push("/project")}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <AboutCont description={homeCfs?.items?.[0]?.fields?.about} />

          <div
            className={`container padding-section ${styles.proj_wrp} ${styles.nobdr}`}
          >
            <div className={`${styles.heading}`}>
              <h2>Frequently Asked Questions</h2>
            </div>
            <div className={styles.list}>
              {faqConst?.map((faq: any, index: number) => {
                if (index < 4) {
                  return (
                    <FaqList
                      qTitle={faq?.title}
                      qAnswer={faq?.answer}
                      qContinue={faq?.Continue || ""}
                      qLink={faq?.link || ""}
                      key={faq?.id}
                      qAnswerTwo={faq?.answerTwo}
                      qLinkTwo={faq?.linkTwo}
                      qContinueTwo={faq?.ContinueTwo}
                      qAnswerThree={faq?.answerThree}
                      qLinkThree={faq?.linkThree}
                      qLinkText={faq?.linkText}
                      qList={faq?.list}
                    />
                  );
                }
              })}
            </div>
            <Grid container>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "center" }}
                className={`${styles.btnexpfaq}`}
              >
                <div>
                  <ButtonComponent
                    text="Explore More"
                    variant="outlined"
                    cls="secondary"
                    handleClick={() => router.push("/faq")}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      )}
    </>
  );
};

export default NewLanding;
