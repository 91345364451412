import styles from "./AboutCont.module.css";
import parse from 'html-react-parser';

interface AboutProps {
  description?: string;
}

const AboutCont = ({ description }: AboutProps) => {
  return (
    <div className={`${"container"} ${styles["AboutContcontainer"]}`}>
      <h2 className={styles.subHeading}>About Pace Verso</h2>

      <p id="desc">{parse((description ?? "").replaceAll("\n", "<br/>"))}</p>
    </div>
  );
};

export default AboutCont;
